<template>
  <form action>
    <div class="modal-card">
      <header class="modal-card-head">
        <p v-if="!editMode" class="modal-card-title">{{ $t('contact.new') }}</p>
        <p v-if="editMode" class="modal-card-title">{{ $t('contact.edit') }}</p>
      </header>
      <section class="modal-card-body">
        <ControlField label="Type" v-if="!this.type">
          <TableFilterSelect :value="entity.type" @input="changeType" :items="clientTypeItems" :allow-empty="$t('common.selectType')" />
        </ControlField>
        <PersonForm
          v-if="'PERSON' === entity.type"
          ref="personForm"
          :config="{ allowVerification: false }"
          :value="entity.data"
          @update="onChange"
          @submit="submit"
        />
        <CompanyForm
          v-if="'COMPANY' === entity.type"
          ref="companyForm"
          :config="{ allowVerification: false }"
          :value="entity.data"
          @update="onChange"
          @submit="submit"
        />
        <div class="buttons" v-if="entity.type">
          <button type="button" class="button is-primary" @click="formSubmit">{{ $t('form.save') }}</button>
        </div>

      </section>
    </div>
  </form>
</template>

<script>
import TableFilterSelect from '@/components/form/TableFilterSelect.vue';
import PersonForm from '@/modules/contact/components/PersonForm.vue';
import CompanyForm from '@/modules/contact/components/CompanyForm.vue';
import enums from '@/utils/enums';
import ContactService from '@/services/ContactService';
import { diffRecursive } from '@/utils/diffRecursive';
import { cloneDeep } from 'lodash';

export default {
  name: 'CreateContactDialog',
  components: {
    TableFilterSelect,
    PersonForm,
    CompanyForm,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    },
    type: {
      type: String
    },
    tenant_id: {
      type: String
    },
  },

  data() {
    return {
      entity: {
        type: this.type,
        language: 'nl'
      },
      relations: []
    };
  },

  created() {
    if (this.value) {
      this.entity = JSON.parse(JSON.stringify(this.value));
      if (this.entity.type === 'PERSON') {
        const parts = this.entity.name.split(' ');
        if (!this.entity.firstname && parts.length > 0) {
          this.entity.firstname = parts.shift();
        }
        if (!this.entity.lastname && parts.length > 0) {
          this.entity.lastname = parts.join(' ');
        }
      }
      if (this.tenant_id) {
        this.entity.tenant_id = this.tenant_id;
      }
    }
  },

  computed: {
    clientTypeItems() {
      const retVal = [];
      for (let type in enums.ContactType) {
        retVal.push({
          field: type,
          label: enums.ContactType[type]
        });
      }

      return retVal.sort((a, b) => a.label.localeCompare(b.label));
    }
  },

  methods: {
    onChange(obj) {
      this.entity.data = cloneDeep(obj.data);
    },
    formSubmit() {
      switch (this.entity.type) {
        case 'PERSON':
          this.$refs.personForm.submit();
          break;
        case 'COMPANY':
          this.$refs.companyForm.submit();
          break;
      }
    },
    async submit(dynamicData) {
      const data = Object.assign({}, this.entity, dynamicData);
      if (this.entity.id) {
        this.entity = (await ContactService.update(this.entity.id, data)).data;
      } else {
        this.entity = (await ContactService.create(data)).data;
      }
      this.$emit('submit', this.entity);
      this.$parent.close();
    },
    forwardCancel() {
      this.$emit('cancel');
      this.$parent.close();
    },
    changeType(type) {
      this.entity.type = type;
      if ('PERSON' === this.entity.type) {
        const name = this.entity.name || '';
        const parts = name.split(' ');
        this.entity.firstname = parts.shift() || '';
        this.entity.lastname = parts.join(' ');
      }
    }
  }
};
</script>
